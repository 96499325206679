import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components/macro';
import ParagraphShort from '../shared/text/ParagraphShort';
import ColorBox from '../shared/wraps/ColorBox/ColorBox';
import FadeLink from '../shared/navigations/FadeLink/FadeLink';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronRight } from '@fortawesome/free-solid-svg-icons';
import useLng from "../../hooks/useLng";

const BlockWrap = styled.div`
  position: relative;
  background-color: ${({ theme }) => theme.colors.main};

  &:hover {
    .contentWrap {
      transform: translateY(-10rem);

      &::before {
        transform: scaleY(1);
      }
    }
  }
  @media screen and (max-width: 767px) {
    &:hover {
      .contentWrap {
        transform: none;

        &::before {
          transform: scaleY(0);
        }
      }
    }
  }
`;

const ContentWrap = styled.div`
  position: relative;
  display: block;
  background-color: ${({ theme }) => theme.colors.white};
  padding: 5.9rem 7.2rem 14.9rem 5rem;
  height: 100%;
  z-index: 1;
  transition: 0.6s ease;
  border-left: 1px solid ${({ theme }) => theme.colors.gray4};
  border-right: 1px solid ${({ theme }) => theme.colors.gray4};

  &::before {
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 4px;
    background-color: ${({ theme }) => theme.colors.main};
    transform: scaleY(0);
    transform-origin: top;
    transition: transform 0.3s ease;
  }

  @media screen and (max-width: 991px) {
    padding-bottom: 9rem;
  }

  @media screen and (max-width: 767px) {
    border: none;
    padding: 43px 30px 36px;
  }
`;

const Icon = styled.img`
  max-width: 12rem;
  max-height: 13rem;
  height: auto;
  margin-bottom: 2.2rem;

  ${({ xl }) =>
    xl &&
    `
    max-width: 49px;
    max-height: 52px;
  `};

  @media screen and (max-width: 767px) {
    margin-bottom: 25px;
  }
`;

const Title = styled(FadeLink)`
  font-size: 1.8rem;
  line-height: 3rem;
  font-weight: 700;
  color: ${({ theme }) => theme.colors.black};
  margin-bottom: 0.9rem;
  display: block;

  @media screen and (max-width: 767px) {
    font-size: 16px;
    line-height: 30px;
    margin-bottom: 20px;
  }
`;

const ColorButton = styled(ColorBox)`
  position: absolute;
  bottom: 0;
  left: 0;
  z-index: 0;
  text-decoration: none;
  transition: 0.3s ease;
  color: ${({ theme }) => theme.colors.black};
  background-color: ${({ theme }) => theme.colors.main};
  padding-left: 6rem;
  padding-right: 4rem;
  display: flex;
  justify-content: space-between;
  &:hover {
    background-color: ${({ theme }) => theme.colors.white};
    color: ${({ theme }) => theme.colors.black};
  }

  @media screen and (max-width: 767px) {
    position: static;
    margin: 0 auto;
    width: calc(100% - 60px);
  }
`;

const MakeBlock = ({ children, className, icon, xl, title, slug, alt }) => {
  const { t } = useLng()
  return (
      <BlockWrap className={className}>
        <ContentWrap className="contentWrap">
          <Icon className={className} src={icon} alt={alt} xl={xl}/>
          <Title to={`/${slug}`}>{title}</Title>
          <ParagraphShort>{children}</ParagraphShort>
        </ContentWrap>
        <ColorButton as={FadeLink} to={`/${slug}`}>
          {t("czytajWiecej")}
          <FontAwesomeIcon icon={faChevronRight}/>
        </ColorButton>
      </BlockWrap>
  )
}

MakeBlock.propTypes = {
  children: PropTypes.string.isRequired,
  className: PropTypes.string,
  icon: PropTypes.string.isRequired,
  xl: PropTypes.bool,
  title: PropTypes.string.isRequired,
  slug: PropTypes.string.isRequired,
  alt: PropTypes.string.isRequired,
};

MakeBlock.defaultProps = {
  className: '',
  xl: false,
};

export default MakeBlock;
