import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components/macro';
import Arrow from './Arrow';

const Wrap = styled.div`
  display: flex;
  height: 7rem;
`;

const Link = styled.div`
  width: 50%;
  
  &:focus {
    outline: none;
    border: 1px solid ${({theme}) => theme.colors.main}
  }
`;
const PaginationArrows = ({ className, prevId, nextId }) => (
  <Wrap className={className}>
    <Link id={prevId}>
      <Arrow />
    </Link>
    <Link id={nextId}>
      <Arrow right />
    </Link>
  </Wrap>
);

PaginationArrows.propTypes = {
  className: PropTypes.string,
  prevId: PropTypes.string.isRequired,
  nextId: PropTypes.string.isRequired,
};

PaginationArrows.defaultProps = {
  className: '',
};

export default PaginationArrows;
