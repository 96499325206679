import React from 'react';
import styled from 'styled-components/macro';
import Section from '../../../shared/wraps/Section/Section';
import GridBlock from '../../../shared/wraps/GridBlock/GridBlock';
import BigTitle from '../../../shared/text/BigTitle';
import MakeBlock from '../../../MakeBlock/MakeBlock';
import ParagraphShort from '../../../shared/text/ParagraphShort';
import stronyInternetoweIcon from '../../../../assets/images/icons/ikona-strony-internetowe.svg';
import sklepyInternetoweIcon from '../../../../assets/images/icons/ikona-sklepy-internetowe.svg';
import projektowanieGraficzneIcon from '../../../../assets/images/icons/ikona-projektowanie-graficzne.svg';
import dedykowaneAplikacjeIcon from '../../../../assets/images/icons/ikona-dedykowane-systemy.svg';
import SiteBackground from '../../../shared/SiteBackground/SiteBackground';
import { RotatingLetter } from 'src/components/shared/Animations/Animations';
import {useI18next} from "gatsby-plugin-react-i18next";
import useLng from "../../../../hooks/useLng";

const StyledSection = styled(Section)`
  .rightParagraphBlock {
    grid-row: 1/2;
    grid-column: 4/5;
  }
  .makeBlock1,
  .makeBlock2,
  .makeBlock3,
  .makeBlock4 {
    background-color: #fff;
    grid-row: 2/3;
  }

  .makeBlock1 {
    grid-column: 1/2;
  }

  .makeBlock2 {
    grid-column: 2/3;
  }

  .makeBlock3 {
    grid-column: 3/4;
  }

  .makeBlock4 {
    grid-column: 4/5;
  }

  @media screen and (max-width: 991px) {
    .rightParagraphBlock {
      grid-row: 2/3;
      grid-column: 3/4;
    }

    .makeBlock1 {
      grid-column: 1/2;
    }

    .makeBlock2 {
      grid-column: 2/3;
    }

    .makeBlock3 {
      grid-row: 3/4;
      grid-column: 1/2;
    }

    .makeBlock4 {
      grid-row: 3/4;
      grid-column: 2/3;
    }
  }
`;

const StyledBigTitle = styled(BigTitle)`
  margin: 8rem auto 12rem;
  grid-row: 1/2;
  grid-column: 2/4;
  max-width: 70rem;
  text-align: center;

  @media screen and (max-width: 991px) {
    grid-column: 1/4;
    margin-bottom: 10rem;
  }

  @media screen and (max-width: 767px) {
    margin-top: 63px;
    margin-bottom: 28px;
  }
`;

const StyledParagraph = styled(ParagraphShort)`
  margin-bottom: 10.9rem;
  @media screen and (max-width: 991px) {
    margin-bottom: 0;
  }
  @media screen and (max-width: 767px) {
    margin-bottom: 12px;
  }
`;

const NoWrap = styled.span`
  white-space: nowrap;
`;

const Make = () => {
    const {t} = useLng()
    const {language} = useI18next()
  return (
    <StyledSection>
      <SiteBackground bgcAllSite />
        {language === 'pl' ? (<StyledBigTitle className="bigTitle">
        C<RotatingLetter>o</RotatingLetter>{' '}
        <NoWrap>
          robim<RotatingLetter delay={0.4}>y</RotatingLetter>
        </NoWrap>
      </StyledBigTitle>): (
            <StyledBigTitle className="bigTitle">
                Wha<RotatingLetter>t</RotatingLetter>{' '}
                <NoWrap>
                   we d<RotatingLetter delay={0.4}>o</RotatingLetter>
                </NoWrap>
            </StyledBigTitle>
        )}
      <GridBlock className="rightParagraphBlock" padding>
        <StyledParagraph>
            {t("whatWeDoText")}
        </StyledParagraph>
      </GridBlock>
      <MakeBlock
        className="makeBlock1"
        title={t("stronyInternetowe")}
        slug="strony-internetowe"
        icon={stronyInternetoweIcon}
        alt="Ikona stron internetowych"
        xl
      >

          {t("makeText1")}
      </MakeBlock>
      <MakeBlock
        className="makeBlock2"
        title={t("sklepyInternetowe")}
        slug="sklepy-internetowe"
        icon={sklepyInternetoweIcon}
        alt="Ikona sklepów internetowych"
        xl
      >
          {t("makeText2")}

      </MakeBlock>
      <MakeBlock
        className="makeBlock3"
        title={t("projektowanieGraficzne")}
        slug="projektowanie-graficzne"
        icon={projektowanieGraficzneIcon}
        alt="Ikona projektowania graficznego"
      >
          {t("makeText3")}
      </MakeBlock>
      <MakeBlock
        className="makeBlock4"
        title={t("dedykowaneAplikacjeISystemy")}
        slug="dedykowane-aplikacje-i-systemy"
        icon={dedykowaneAplikacjeIcon}
        alt="Ikona dedykowanych aplikacji"
      >
          {t("makeText4")}
      </MakeBlock>
    </StyledSection>
  );
};

export default Make;
