import React, { useContext } from 'react';
import SiteBackground from '../../../shared/SiteBackground/SiteBackground';
import Section from '../../../shared/wraps/Section/Section';
import Block from '../../../shared/wraps/Block/Block';
import GridBlock from '../../../shared/wraps/GridBlock/GridBlock';
import SocialMedia from '../../../SocialMedia/SocialMedia';
import PaginationArrows from '../../../shared/navigations/Pagination/PaginationArrows';
import BigTitle from '../../../shared/text/BigTitle';
import Slider from '../../../Slider/Slider';
import styled from 'styled-components/macro';
import ParagraphShort from '../../../shared/text/ParagraphShort';
import { graphql, useStaticQuery } from 'gatsby';
import FadeLink from '../../../shared/navigations/FadeLink/FadeLink';
import ParagraphInfo from '../../../shared/text/ParagraphInfo';
import {
  RotatingLetter,
  NoWrap,
} from 'src/components/shared/Animations/Animations';
import { TranslationsContext } from 'src/translations/TranslationsProvider';
import {useI18next} from "gatsby-plugin-react-i18next";
import useLng from "../../../../hooks/useLng";
import {VideoModal} from "../../../videoModal";

const StyledGridSection = styled.div`
  .socialBlock {
    grid-row: 1/2;
    grid-column: 1/2;
  }

  .titleBlock {
    grid-row: 1/2;
    grid-column: 2/4;
  }

  .portfolioBtnBlock {
    grid-row: 1/2;
    grid-column: 4/5;
  }

  .portfolioSliderBlock {
    position: relative;
    grid-row: 2/3;
    grid-column: 1/5;
  }

  @media screen and (max-width: 991px) {
    .socialBlock {
      display: none;
    }

    .titleBlock {
      grid-row: 1/2;
      grid-column: 1/3;
      justify-content: flex-start;
    }

    .portfolioBtnBlock {
      grid-row: 1/2;
      grid-column: 3/4;
    }

    .portfolioSliderBlock {
      grid-row: 2/3;
      grid-column: 1/5;
    }
  }

  @media screen and (max-width: 767px) {
    .socialBlock {
      display: flex;
      order: 3;
    }
  }
`;

const StyledBigTitle = styled(BigTitle)`
  margin-bottom: 12rem;

  @media screen and (max-width: 991px) {
    margin-bottom: 3rem;
  }

  @media screen and (max-width: 767px) {
    margin-top: 32px;
    margin-bottom: 27px;
  }
`;

const StyledSocialMedia = styled(SocialMedia)`
  margin-bottom: 5rem;
  @media screen and (max-width: 991px) {
    display: none;
  }
`;

const StyledPaginationArrows = styled(PaginationArrows)`
  position: absolute;
  z-index: 200;
  left: 25%;
  bottom: 0;
  transform: translateX(-50%);
  width: 36rem;

  @media screen and (max-width: 1200px) {
    left: 50%;
  }

  @media screen and (max-width: 991px) {
    left: 0;
    transform: translateX(0);
  }
  @media screen and (max-width: 767px) {
    display: flex;
  }
`;

const StyledParagraph = styled(ParagraphShort)`
  margin-bottom: 24.4rem;
  transform: translateY(50%);
  @media screen and (max-width: 767px) {
    display: none;
  }
`;

const StyledFadeLink = styled(FadeLink)`
  color: ${({ theme }) => theme.colors.black};
  text-decoration: underline;
  font-size: inherit;
  margin-left: 1rem;
  display: ${({ mobile }) => mobile && 'none'};

  @media screen and (max-width: 767px) {
    margin-left: -0.8rem;
    margin-right: auto;
    display: ${({ mobile }) => (mobile ? 'block' : 'none')};
    font-size: 20px;
  }
`;

const StyledInfoWrapper = styled(GridBlock)`
  margin: 6rem 0;
  max-width: 92rem;
  grid-column: 2/5;
`;

const StyledDescriptionSection = styled(Section)`
  grid-template-columns: repeat(4, 25%);
`;
const OrderValuation = styled(ParagraphInfo)`
  text-decoration: none;
  color: ${({ theme }) => theme.colors.black4};
  transition: 0.3s ease;
  border: 2px solid ${({ theme }) => theme.colors.main};
  border-radius: 0;
  cursor: pointer;
  padding: 0.8rem 1.4rem;
  font-weight: normal;
  opacity: 1;
  //margin-left: -1.4rem;
  font-size: 1.6rem;
  margin-top: 1rem;
  display: inline-flex;
  align-items: center;
  svg{
    margin-left: 1.4rem;
    fill: ${({ theme }) => theme.colors.black4};
  }
  &:hover {
    opacity: 0.7;
  }
`;

const Header = ({setOpen}) => {
  const data = useStaticQuery(portfolioQuery);
  const { language } = useI18next();
  const { t } = useLng()
  const btn = React.useRef()
  React.useEffect(() => {
    const params = new URLSearchParams(location.search);
    const modalParam = params.get("modal");
    if (modalParam && btn.current){
      setTimeout(() => {
        btn.current.click();
      }, 100)
    }
  }, [btn.current])
  return (
      <>
    <StyledGridSection>
      <Section first>
        <SiteBackground nonBgc />
        <GridBlock className="socialBlock">
          <Block padding>
            <StyledSocialMedia />
          </Block>
        </GridBlock>
        <GridBlock className="titleBlock">
          {language === 'pl' ? (
            <StyledBigTitle mb="6.4rem">
              Nasz<RotatingLetter delay={0.8}>e</RotatingLetter>{' '}
              <NoWrap>
                realizacj
                <RotatingLetter delay={1.2}>e</RotatingLetter>
              </NoWrap>
            </StyledBigTitle>
          ) : (
            <StyledBigTitle mb="6.4rem">
              Ou<RotatingLetter delay={0.8}>r</RotatingLetter>{' '}
              <NoWrap>
                realization
                <RotatingLetter delay={1.2}>s</RotatingLetter>
              </NoWrap>
            </StyledBigTitle>
          )}
        </GridBlock>
        <GridBlock className="portfolioBtnBlock">
          <Block padding>
            <StyledParagraph>
              {t("zajmujemySie")} <br/>
              <OrderValuation onClick={setOpen} ref={btn}>
                {language === 'pl' ?
                "Obchodzimy 15 urodziny"
                :"We're celebrating our 15th birthday!"}

                <svg fill="#111111" height="24px" width="24px" version="1.1" id="Layer_1" viewBox="0 0 459 459" xmlSpace="preserve">
                  <g>
                      <g>
                          <path d="M229.5,0C102.751,0,0,102.751,0,229.5S102.751,459,229.5,459S459,356.249,459,229.5S356.249,0,229.5,0z M310.292,239.651    l-111.764,76.084c-3.761,2.56-8.63,2.831-12.652,0.704c-4.022-2.128-6.538-6.305-6.538-10.855V153.416    c0-4.55,2.516-8.727,6.538-10.855c4.022-2.127,8.891-1.857,12.652,0.704l111.764,76.084c3.359,2.287,5.37,6.087,5.37,10.151    C315.662,233.564,313.652,237.364,310.292,239.651z"/>
                      </g>
                  </g>
                  </svg>

              </OrderValuation>
            </StyledParagraph>
          </Block>
        </GridBlock>
        <GridBlock className="portfolioSliderBlock">
          <Slider slides={data} />
          <StyledPaginationArrows prevId="sliderPrev" nextId="sliderNext" />
        </GridBlock>
      </Section>
      <StyledDescriptionSection>
        <SiteBackground nonBgc />
        <StyledInfoWrapper padding>
          <ParagraphInfo big>
            {t("tworzeniemStron")}
          </ParagraphInfo>
          <ParagraphInfo big>
            {t("zespolSkladaSie")}
            <div style={{display: "flex", justifyContent: "center", width: "100%", marginTop: "10px"}}>
            <StyledFadeLink stylish to="/o-makadu">
              {t("czytajWiecej")}
            </StyledFadeLink>
            </div>
          </ParagraphInfo>
          <StyledFadeLink stylish mobile to="/o-makadu">
            {t("czytajWiecej")}
          </StyledFadeLink>
        </StyledInfoWrapper>
      </StyledDescriptionSection>
    </StyledGridSection>
  </>
  );
};

const portfolioQuery = graphql`
  {
    allDirectusProjekty(
      filter: {main_page: {eq: true}}
      sort: {order: DESC, fields: waga}
    ) {
      nodes {
        title
        slug
        preview {
          localFile {
            childImageSharp {
              fluid(maxWidth: 450, maxHeight: 450, quality: 90) {
                ...GatsbyImageSharpFluid_withWebp_noBase64
              }
            }
          }
        }
         prew_hp {
          localFile {
            childImageSharp {
              fluid(maxWidth: 450, maxHeight: 450, quality: 90) {
                ...GatsbyImageSharpFluid_withWebp_noBase64
              }
            }
          }
        }
      }
    }
  }
`;

export default Header;
