import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components/macro';
import ParagraphShort from '../shared/text/ParagraphShort';
import diImg from '../../assets/images/clients/di.png';
import charlieImg from '../../assets/images/clients/charlie.png';
import proArtImg from '../../assets/images/clients/pro_art.png';
import solnyImg from '../../assets/images/clients/solny_invest.png';
import stxImg from '../../assets/images/clients/stx.png';
import werandaImg from '../../assets/images/clients/weranda.jpg';
import archeImg from 'src/assets/images/clients/arche.png';
import paprockiBrzozowskiImg from 'src/assets/images/clients/paprockibrzozowski.png';
import concordiaImg from 'src/assets/images/clients/concorida_design.png';
import oriflameImg from 'src/assets/images/clients/oriflame-logo.png';
import stowarzyszenieSomelierowImg from 'src/assets/images/clients/ssp.png';
import { Swiper, SwiperSlide } from 'swiper/react';
import SwiperCore, { Navigation, A11y } from 'swiper';
import 'swiper/swiper-bundle.min.css';
import useSlidesPerView from '../../hooks/useSlidesPerView';
import Img from 'gatsby-image';
import { graphql, useStaticQuery } from 'gatsby';
import { useI18next} from "gatsby-plugin-react-i18next";

const sliderData = [
  {
    quote: `“Zespół Makadu wykonał dla nas rebranding identyfikacji wizualnej całej grupy Arche. Zaprojektował również logotypy i materiały dla wybranych obiektów.”`,
    quoteEN: `“The Makadu team rebranded the visual identity of the entire Arche group for us. He also designed logotypes and materials for selected facilities.”`,
    link: 'https://www.arche.pl/',
    logo: archeImg,
    alt: 'Grupa Arche',
  },
  {
    "quote": "“Programiści Makadu świadczą dla nas usługi wsparcia i rozbudowy strony oraz sklepu internetowego. Polecamy.”",
    "quoteEN": "“Makadu programmers provide support and expansion services for our website and online store. We recommend them.”",
    "link": "https://butik.paprockibrzozowski.com/",
    "logo": "paprockiBrzozowskiImg",
    "alt": "Paprocki&Brzozowski"
  },
  {
    "quote": "“Jakość zrealizowanych kreacji i sposób obsługi zleceń na bardzo wysokim poziomie. Rekomendujemy Makadu do wymagających projektów.”",
    "quoteEN": "“The quality of executed designs and the handling of orders are at a very high level. We recommend Makadu for demanding projects.”",
    "link": "https://www.concordiadesign.pl/",
    "logo": "concordiaImg",
    "alt": "Concordia Design"
  },
  {
    "quote": "“Pracowaliśmy razem przy kilku projektach konkursów realizowanych dla naszych konsultantów. Współpraca przebiegała bardzo owocnie.”",
    "quoteEN": "“We collaborated on several contest projects conducted for our consultants. The cooperation was very fruitful.”",
    "link": "https://pl.oriflame.com/",
    "logo": "oriflameImg",
    "alt": "Oriflame"
  },
  {
    "quote": "“Pracownicy Makadu zbudowali, rozbudowują i wspierają nasze stowarzyszenie w działaniach prowadzonych w internecie. Polecamy Pana Kamila i jego zespół.”",
    "quoteEN": "“Makadu employees have built, expanded, and supported our association in online activities. We recommend Mr. Kamil and his team.”",
    "link": "https://sommelierzy.pl/",
    "logo": "stowarzyszenieSomelierowImg",
    "alt": "Stowarzyszenie Sommelierów Polskich"
  },
  {
    "quote": "“Polecam Agencję Makadu jako partnera z ciekawymi pomysłami, który rzetelnie podchodzi do powierzonych projektów.”",
    "quoteEN": "“I recommend Makadu Agency as a partner with interesting ideas, who approaches entrusted projects diligently.”",
    "link": "https://werandafamily.com",
    "logo": "werandaImg",
    "alt": "Weranda Family"
  },
  {
    "quote": "“Działania agencji charakteryzują się elastycznością, rzetelnością i kompleksowym podejściem do każdego z zadań.”",
    "quoteEN": "“The agency's actions are characterized by flexibility, reliability, and a comprehensive approach to each task.”",
    "link": "https://www.di-factory.com/pl",
    "logo": "diImg",
    "alt": "Di Factory"
  },
  {
    "quote": "“Na szczególne wyróżnienie zasługuje inicjatywa, gotowość do szybkiego działania i jakość usługi.”",
    "quoteEN": "“Special recognition is deserved for the initiative, readiness for quick action, and the quality of service.”",
    "link": "https://www.stxmusic.pl",
    "logo": "stxImg",
    "alt": "STX Music Solutions"
  },
  {
    "quote": "“Z całą odpowiedzialnością Solny Investment rekomenduje współpracę z Agencją Makadu.”",
    "quoteEN": "“Solny Investment wholeheartedly recommends cooperation with Makadu Agency.”",
    "link": "http://solnyhotel.pl/pl/",
    "logo": "solnyImg",
    "alt": "Hotel Solny"
  },
  {
    "quote": "“Zespół Makadu zrealizował dla nas kilka stron internetowych, które w całkowicie spełniły nasze oczekiwania.”",
    "quoteEN": "“The Makadu team created several websites for us that fully met our expectations.”",
    "link": "https://www.pro-art.pl/pl",
    "logo": "proArtImg",
    "alt": "PROART Maciej Szulc"
  },
  {
    "quote": "“Programiści Makadu przygotowali od podstaw i obsługują technicznie nasz sklep internetowy - polecam jako specjalistów w swojej branży.”",
    "quoteEN": "“Makadu programmers built and technically support our online store from scratch - I recommend them as specialists in their field.”",
    "link": "https://www.ohcharlie.eu/pl/",
    "logo": "charlieImg",
    "alt": "Och Charlie"
  }
];

const SwiperWrap = styled.div`
  width: 100%;
  overflow: hidden;
  .swiper-slide {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: 5rem 6.5rem 4.5rem 5.5rem;
    background-color: #fff;
    border-left: 1px solid ${({ theme }) => theme.colors.gray4};
    border-right: 1px solid ${({ theme }) => theme.colors.gray4};
    height: auto;
  }
`;

const Quote = styled(ParagraphShort)`
  position: relative;
  padding-bottom: 3.8rem;
  margin-bottom: 0;
`;

const Logo = styled(Img)`
  width: 100%;
`;

const LogoLink = styled.a`
  width: ${({ width }) => (width ? width : '9rem')};
`;

SwiperCore.use([Navigation, A11y]);

const SliderReferences = props => {
  const { className } = props;
  const slidesPerView = useSlidesPerView(3);
  const { nodes } = useStaticQuery(clientsQuery).allFile;
  const {language} = useI18next()

  const clientsData = sliderData.map(client => {
    let logo = {};

    switch (client.alt) {
      case 'Grupa Arche':
        logo = nodes.find(({ name }) => name === 'arche').childImageSharp.fluid;
        break;
      case 'Paprocki&Brzozowski':
        logo = nodes.find(({ name }) => name === 'paprockibrzozowski')
          .childImageSharp.fluid;
        break;
      case 'Concordia Design':
        logo = nodes.find(({ name }) => name === 'concorida_design')
          .childImageSharp.fluid;
        break;
      case 'Oriflame':
        logo = nodes.find(({ name }) => name === 'oriflame-logo')
          .childImageSharp.fluid;
        break;
      case 'Stowarzyszenie Sommelierów Polskich':
        logo = nodes.find(({ name }) => name === 'ssp').childImageSharp.fluid;
        break;
      case 'Weranda Family':
        logo = nodes.find(({ name }) => name === 'weranda').childImageSharp
          .fluid;
        break;
      case 'Di Factory':
        logo = nodes.find(({ name }) => name === 'di').childImageSharp.fluid;
        break;
      case 'STX Music Solutions':
        logo = nodes.find(({ name }) => name === 'stx').childImageSharp.fluid;
        break;
      case 'Hotel Solny':
        logo = nodes.find(({ name }) => name === 'solny_invest').childImageSharp
          .fluid;
        break;
      case 'PROART Maciej Szulc':
        logo = nodes.find(({ name }) => name === 'pro_art').childImageSharp
          .fluid;
        break;
      case 'Och Charlie':
        logo = nodes.find(({ name }) => name === 'charlie').childImageSharp
          .fluid;
        break;
      default:
        logo = nodes.find(({ name }) => name === 'charlie').childImageSharp
          .fluid;
    }

    return { ...client, logo };
  });

  return (
    <SwiperWrap className={className}>
      <Swiper
        slidesPerView={slidesPerView}
        navigation={{
          prevEl: '#sliderPrevReferences',
          nextEl: '#sliderNextReferences',
        }}
        loop
      >
        {clientsData.map(({ quote, quoteEN,  link, logo, alt }) => (
          <SwiperSlide>
            <Quote>{language === "pl" ? quote : quoteEN}</Quote>
            <LogoLink
              rel="nofollow"
              href={link}
              width={alt === 'Grupa Arche' && '8rem'}
            >
              <Logo fluid={logo} alt={alt} />
            </LogoLink>
          </SwiperSlide>
        ))}
      </Swiper>
    </SwiperWrap>
  );
};

SliderReferences.propTypes = {
  className: PropTypes.string,
};

SliderReferences.defaultProps = {
  className: '',
};

export const clientsQuery = graphql`
  query ClientsQuery {
    allFile(filter: { relativeDirectory: { eq: "clients" } }) {
      nodes {
        name
        childImageSharp {
          fluid(maxWidth: 220, quality: 90) {
            ...GatsbyImageSharpFluid_withWebp_noBase64
          }
        }
      }
    }
  }
`;

export default SliderReferences;
