import React, {useRef, useState} from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import styled from "styled-components/macro";
// import Plyr from 'plyr';
import 'plyr/dist/plyr.css';
// window != undefined && import Plyr from "plyr-react"
// import "plyr-react/plyr.css"
// let Plyr =  window !== undefined ? await import('plyr-react') : null;


const Close = styled.button`
  border: none;
  outline: none;
  background: transparent;
  position: absolute;
  top: 3rem;
  right: 3rem;
  cursor: pointer;
`;

const VideoBox = styled.div`
  height: 95%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: auto;
`;
const plyrProps = {
    source: {
        type: 'video',
        sources: [
            {
                // src: '"/images/15yo.mp4"',
                src: '/images/15yo.mp4',
                type: 'video/mp4',
            },
        ],

    } , // https://github.com/sampotts/plyr#the-source-setter
    options: {
        autoplay: true,
    }, // https://github.com/sampotts/plyr#options
    // Direct props for inner video tag (mdn.io/video)
}

export const VideoModal = ({ isOpen, onClose }) => {
    const [isAnimating, setIsAnimating] = useState(false);
    const ref = useRef();
    const closeModal = () => {
        setIsAnimating(true);
        setTimeout(() => {
            onClose();
            setIsAnimating(false);
        }, 100); // Duration of closing animation
    };

    React.useEffect(() => {
        if(typeof window !== "undefined" && typeof document !== "undefined"){
            if (ref.current) {
                const Plyr = require('plyr');
                new Plyr(ref.current,  {autoplay: true});
            }
        }
    }, [isOpen])


    return (
        <AnimatePresence>
            {isOpen && (
                <motion.div
                    initial={{ scale: 0, opacity: 0 }}
                    animate={{ opacity: 1, scale: 1}}
                    exit={{ opacity: 0, scale: 0 }}
                    transition={{ type: 'ease', duration: 0.8 }}
                    style={{
                        position: 'fixed',
                        bottom: 0,
                        right: 0,
                        padding: '20px',
                        background: 'rgba(0,0,0,0.85)',
                        boxShadow: '0 0 10px rgba(0, 0, 0, 0.2)',
                        zIndex: 99999,
                        height: "100%",
                        width: "100%",
                        pointerEvents: isAnimating ? 'none' : 'auto',
                    }}
                >
                    <Close onClick={closeModal}>
                        <svg xmlns="http://www.w3.org/2000/svg" width="65px" height="65px" viewBox="0 0 24 24" fill="none">
                            <path fill="white" fillRule="evenodd" clipRule="evenodd" d="M5.29289 5.29289C5.68342 4.90237 6.31658 4.90237 6.70711 5.29289L12 10.5858L17.2929 5.29289C17.6834 4.90237 18.3166 4.90237 18.7071 5.29289C19.0976 5.68342 19.0976 6.31658 18.7071 6.70711L13.4142 12L18.7071 17.2929C19.0976 17.6834 19.0976 18.3166 18.7071 18.7071C18.3166 19.0976 17.6834 19.0976 17.2929 18.7071L12 13.4142L6.70711 18.7071C6.31658 19.0976 5.68342 19.0976 5.29289 18.7071C4.90237 18.3166 4.90237 17.6834 5.29289 17.2929L10.5858 12L5.29289 6.70711C4.90237 6.31658 4.90237 5.68342 5.29289 5.29289Z" />
                        </svg>
                    </Close>
                    <VideoBox>
                        {/*<div style={{width: "800px", maxWidth: "90vw"}}>*/}
                        {/*    {typeof  window != "undefined" && typeof document != "undefined" && <Plyr ref={(player) => (ref.current = player)} {...plyrProps}  />}*/}
                        {/*</div>*/}
                        <video autoPlay ref={ref} id="player"  style={{width: "800px", maxWidth: "90vw"}} playsInline controls>
                            <source src="/images/15yo.mp4" type="video/mp4" />
                        </video>
                    </VideoBox>
                </motion.div>
            )}
        </AnimatePresence>
    );
};
