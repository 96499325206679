import { useEffect, useState } from 'react';

export default (maxSlidesPerView = 4) => {
  const [windowSize, setWindowSize] = useState({
    width: undefined,
    height: undefined,
  });

  useEffect(() => {
    function handleResize() {
      setWindowSize({
        width: window.innerWidth,
      });
    }
    window.addEventListener('resize', handleResize);
    handleResize();
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  if (windowSize.width < 767) {
    return 1;
  }

  if (windowSize.width < 991) {
    return 2;
  }

  if (windowSize.width < 1199) {
    return 3;
  }

  return maxSlidesPerView;
};
