import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import Img from 'gatsby-image';
import Block from '../shared/wraps/Block/Block';
import { Swiper, SwiperSlide } from 'swiper/react';
import SwiperCore, { Navigation, A11y } from 'swiper';
import useSlidesPerView from '../../hooks/useSlidesPerView';
import FadeLink from '../shared/navigations/FadeLink/FadeLink';

const Wrapper = styled.div`
  overflow: hidden;
`;

const StyledImg = styled(Img)`
  height: 100%;
  max-width: 100vw !important;
`;

const StyledFadeLink = styled(FadeLink)`
  height: 100%;
`;

SwiperCore.use([Navigation, A11y]);

const Slider = props => {
  const {
    slides: {
      allDirectusProjekty: { nodes: allSlides },
    },
  } = props;

  const slidesPerView = useSlidesPerView();
  return (
    <Wrapper>
      <Swiper
        slidesPerView={slidesPerView}
        navigation={{ nextEl: '#sliderNext', prevEl: '#sliderPrev' }}
        loop
        threshold={2}
      >
        {allSlides.map(slide => {
          return (
            <SwiperSlide key={slide.title}>
              <Block height="46rem">
                <StyledFadeLink to={`/projekty/${slide.slug}`}>
                  <StyledImg
                    fluid={slide.prew_hp ? slide.prew_hp.localFile?.childImageSharp?.fluid : slide.preview?.localFile?.childImageSharp?.fluid}
                    alt={slide.title}
                  />
                </StyledFadeLink>
              </Block>
            </SwiperSlide>
          );
        })}
      </Swiper>
    </Wrapper>
  );
};

Slider.defaultProps = {
  slides: {},
};

Slider.propTypes = {
  slides: PropTypes.shape({}),
};

export default Slider;
