import React from 'react';
import styled from 'styled-components';
import Section from '../../../shared/wraps/Section/Section';
import GridBlock from '../../../shared/wraps/GridBlock/GridBlock';
import BigTitle from '../../../shared/text/BigTitle';
import SiteBackground from '../../../shared/SiteBackground/SiteBackground';
import SliderReferences from '../../../Slider/SliderReferences';
import PaginationArrows from '../../../shared/navigations/Pagination/PaginationArrows';
import { RotatingLetter } from 'src/components/shared/Animations/Animations';
import {useI18next} from "gatsby-plugin-react-i18next";

const StyledSection = styled(Section)`
  .sliderReferences {
    grid-row: 2/3;
    grid-column: 1/4;
  }

  .paginationArrows {
    grid-row: 2/3;
    grid-column: 4/5;
  }

  @media screen and (max-width: 991px) {
    .sliderReferences {
      grid-row: 2/3;
      grid-column: 1/4;
    }

    .paginationArrows {
      display: none;
    }
  }

  @media screen and (max-width: 767px) {
    .paginationArrows {
      display: flex;
    }
  }
`;

const StyledBigTitle = styled(BigTitle)`
  margin-top: 12rem;
  margin-bottom: 8rem;
  grid-row: 1/2;
  grid-column: 2/4;

  @media screen and (max-width: 991px) {
    grid-column: 1/4;
    margin-top: 10rem;
    margin-bottom: 10rem;
  }

  @media screen and (max-width: 767px) {
    margin-top: 63px;
    margin-bottom: 28px;
  }
`;

const Clients = () => {
    const {language} = useI18next()
  return (
    <StyledSection>
      <SiteBackground bgcRight />
      <StyledBigTitle className="bigTitle">{
          language === "pl"? <>
          Klienc< RotatingLetter >i< /RotatingLetter>
              </>:<>
          Client<RotatingLetter>s</RotatingLetter>
          </>}
      </StyledBigTitle>
      <SliderReferences className="sliderReferences" />
      <GridBlock className="paginationArrows">
        <PaginationArrows
          prevId="sliderPrevReferences"
          nextId="sliderNextReferences"
        />
      </GridBlock>
    </StyledSection>
  );
};

export default Clients;
